import React, {useEffect, useState} from "react";
import {
    Modal, Button, Form, Loader, FormItem, Tag, useForm, TextEditor, TagsListOptionProps,
    Space, AutoComplete, FilesList, FlagsList, TagsInput, TypographyText, Textarea
} from "@components";
import { KnowledgeVersionForm } from "@pages/Knowledge/KnowledgeVersionForm";
import {
    CategoriesApi, FilesApi, KnowledgesApi,
    LanguagesApi, SectionsApi, TagsApi
} from "@api";
import {
    KnowledgeItemProps, KnowledgeVersionProps,
    FileItemProps, LanguageItemProps, SectionItemProps
} from "@types";
import { KnowledgeSectionForm } from "@pages";
import { useBreakpoint, useTranslator } from "@helpers";
import { FilesModal } from "../Files/FilesModal";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import "./KnowledgeItemForm.less";

const defaultValue: KnowledgeItemProps = {
    id: null,
    content: "",
    title: "",
    tags: [],
    language_id: null
};

const prefixCls = "knowledge-item-form";

export interface KnowledgeItemFormProps {
    id: any
    visible?: boolean
    action?: 'create' | 'edit' | 'copy'
    setVisible: (visible: any) => void
    onSubmit: (values: any, metaData: any) => void
}

export const KnowledgeItemForm = (props: KnowledgeItemFormProps) => {
    const [form] = useForm();
    const { isTablet } = useBreakpoint();
    const {section}: any = useParams();

    const { getLocalisation } = useTranslator("knowledge")

    const {visible, setVisible, id, action, onSubmit} = props;

    const [visibleSectionForm, setVisibleSectionForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
    const [sectionLoading, setSectionLoading] = useState<boolean>(false);
    const [lastItemValues, setLastItemValues] = useState<any>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [record, setRecord] = useState<KnowledgeItemProps>();
    const [optionsTags, setOptionsTags] = useState<TagsListOptionProps[]>([]);
    const [categories, setCategories] = useState<Array<any>>([]);
    const [formVersion, setFormVersion] = useState<KnowledgeVersionProps["id"]>();
    const [files, setFiles] = useState<Array<FileItemProps>>();
    const [fileId, setFileId] = useState<FileItemProps["id"]>();
    const [languages, setLanguages] = useState<LanguageItemProps[]>([]);
    const [sections, setSections] = useState<SectionItemProps[]>();
    const [sectionName, setSectionName] = useState<string>('');
    const [sectionId, setSectionId] = useState<any>(section);

    const handleFinish = (values: any) => {
        setLastItemValues(values);
        if (record && !record.id) {
            setFormVersion("none")
        }
        else if (record && record.actions && record.actions.indexOf("add_version") > -1) {
            setFormVersion("none")
        }
        else if (action === 'copy') {
            setFormVersion("none")
        }
        else if (record && record.actions && record.actions.indexOf("edit") > -1 && action === 'edit') {
            onSubmit && onSubmit({
                ...record,
                ...values,
                id: action === "edit" && id
            }, { setSubmitting });
        }
    }

    const handleSubmitVersion = async (values: any, { setSubmitting }: any) => {
        onSubmit && onSubmit({
            ...record,
            ...lastItemValues,
            id: action === "edit" && id,
            content_id: null,
            version: {...values}
        }, { setSubmitting });
    }

    const handleSubmit = () => {
        form.submit();
    }

    const handleCancel = () => {
        setRecord(undefined);
        setTimeout(() => setVisible(false))
    }

    const handleSubmitTag = async (values: any, { setVisibleForm, setLoadingForm }: any) => {
        setLoadingForm(true);
        const result = await TagsApi.createArticleTag({ ...values});

        if (!!result) {
            const tags = await TagsApi.getArticleTags();
            const addingTag = tags.filter(((item) => item.label === values.label))[0]
            
            setOptionsTags(tags);

            if (addingTag) {
                const tagsValue = [addingTag.value]

                form.getFieldValue('tags') && tagsValue.push(...form.getFieldValue('tags'))

                form.setFieldsValue({
                    tags: tagsValue
                })
            }

            setVisibleForm(() => {
                setLoadingForm(false);
                return false
            });
        }
        else {
            setLoadingForm(false);
        }
    }

    const handleSubmitCategory = async (name: any) => {
        setCategoryLoading(true);
        const result = await CategoriesApi.update(sectionId, {label: name});

        if (result) {
            const categories = await CategoriesApi.getList(sectionId);

            setCategories(categories || []);

            form.setFieldsValue({
                category_id: result.value
            });
        }

        setCategoryLoading(false);
    }

    const handleCreateFile = () => {
        setFileId("none");
    }

    const handleSubmitFile = async(values: any, {setSubmitting}: any) => {
        setSubmitting(true);
        const result = await FilesApi.update({ ...values});

        if (result) {
            const newFiles = await FilesApi.getList();
            const filesId = (files || []).map(({ id }: any) => String(id));

            setFiles([...newFiles.filter(({id}: any) => filesId.indexOf(String(id)) === -1), ...(files || [])]);

            const values = form.getFieldsValue();

            form.setFieldsValue({
                files: [...(values.files || []), result.id]
            })

            setFileId(undefined)
        }
        setSubmitting(false);
    }

    const handleSubmitSection = async (values: any, { setSubmitting }: any) => {
        setSubmitting(true);
        const result = await SectionsApi.update(values);
        if (result.id) {
            const sections = await SectionsApi.getAllList();
            const addingSection = sections.filter(((item) => item.name === values.name))[0]

            setSections(sections);
            if (addingSection?.id) {
                setSectionId(addingSection?.id)
                form.setFieldsValue({ section_id: addingSection.id })
            }
            setVisibleSectionForm(false)
        }
        setSubmitting(false);
    }

    const handleSearchSection = (text?: string) => {
        if (text) {
            setSectionName(text)
        }
    }

    const handleAddSectionClick = () => {
        setVisibleSectionForm(true);
    };

    const handleValuesChange = async(changeValue: any) => {
        const { section_id } = changeValue;

        if (Object.keys(changeValue).indexOf("section_id") > -1) {
            if (section_id) {
                setSectionLoading(true);

                setSectionId(section_id)
                const categories = await CategoriesApi.getList(section_id);
                setCategories(categories)
                form.resetFields(['category_id'])
                setRecord({ ...record, category_id: undefined } as KnowledgeItemProps)
                form.setFieldsValue({ category_id: undefined } as KnowledgeItemProps);

                setSectionLoading(false);
            }
            else {
                setSectionId(undefined)
            }
        }

    }

    const handleRemoveCategory = async (id: any) => {
        setCategoryLoading(true);

        const result = await CategoriesApi.remove(sectionId, id);

        if (result) {
            const categories = await CategoriesApi.getList(sectionId);
            const values = form.getFieldsValue();

            setCategories(categories || []);

            if (values.category_id === id) {
                form.setFieldsValue({
                    category_id: null
                });
                await form.validateFields(["category_id"])
            }

        }

        setCategoryLoading(false);
    }

    const getTitle = () => {
        switch (action) {
            case 'copy':
                return getLocalisation('create_translator_article')
            case 'create':
                return getLocalisation('create_item');
            case 'edit':
                if (record && record.actions && record.actions.indexOf("add_version") > -1) {
                    return getLocalisation('update_item');
                }
                return `${getLocalisation('editing_version')} ${record && record.version ? `(${record.version})` : ""}`
        }
    }

    useEffect(() => {
        const fetchInit = async () => {

            setLoading(true);

            const result = action !== "create" && (await KnowledgesApi.getRecord(id));
            const record: KnowledgeItemProps = {...defaultValue, ...(result || {})};

            const files = await FilesApi.getList();
            const tags = await TagsApi.getArticleTags();
            const sections = await SectionsApi.getAllList();
            const categories = (section || record.section_id) && await CategoriesApi.getList(record.section_id || section);
            let languages = await LanguagesApi.getProjectList();

            if (action === 'copy') {
                const currentLanguages = record.languages?.map((item: any) => item.id) || [];
                languages = languages.filter((item: LanguageItemProps) => !currentLanguages.includes(item.id))
            }

            const recordFiles = ((record && record.files) || []);
            const filesId = recordFiles.map(({id}: any) => String(id));

            setFiles([...files.filter(({id}: any) => filesId.indexOf(String(id)) === -1), ...recordFiles]);
            setOptionsTags(tags);
            setCategories(categories || []);
            setSections(sections)
            setLanguages(languages);

            const values = {
                ...record, ...{
                    tags: record.tags?.map(({ value }: any) => value),
                    files: record.files?.map(({ id }: any) => id),
                    section_id: record.section_id || section
                }
            }

            setSectionId(record.section_id || section)
            setRecord({...values});
            form.setFieldsValue({...values});
            setLoading(false);
        };

        form.resetFields();

        if (visible) {
            fetchInit().catch(console.error);
        }
        else {
            setFormVersion(undefined);
        }
    }, [section, id, visible, form, action]);

    return (
        <>{!!visible && <>
            <Modal
                className={clsx(prefixCls)}
                title={getTitle()}
                visible={visible}
                width={"90vw"}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="confirm"
                        type="primary"
                        onClick={handleSubmit}
                        loading={submitting}
                    >{getLocalisation('confirm')}</Button>,
                    <Button
                        key="cancel"
                        type="secondary"
                        onClick={handleCancel}
                        disabled={submitting}
                    >{getLocalisation('close')}</Button>
                ]}
            >
                <Loader spinning={loading} size="middle">
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={handleFinish}
                        onValuesChange={handleValuesChange}
                        initialValues={{ ...record, language_id: action !== 'copy' && record?.language_id }}
                    >
                        {action !== 'edit' && <FormItem
                            rules={[{ required: true, message: getLocalisation('please_select_language') }]}
                            name="language_id"
                        >
                            <FlagsList options={languages}/>
                        </FormItem>}

                        {action === 'edit' && <FlagsList
                            value={record?.language_id}
                            options={languages}
                        />}

                        <Space
                            className={clsx(`${prefixCls}-row`, {
                                [`${prefixCls}-row-tablet`]: isTablet
                            })}
                            size="large"
                            align="start"
                            direction={isTablet ? "vertical" : "horizontal"}
                        >
                            <Space direction="vertical" size="middle">

                                <FormItem
                                    rules={[
                                        { whitespace: true, message: getLocalisation('please_header') },
                                        { required: true, message: getLocalisation('please_header') },
                                    ]}
                                    label={getLocalisation('header')}
                                    name="title"
                                >
                                    <Textarea placeholder={getLocalisation('write_header')}/>
                                </FormItem>
                                <FormItem
                                    label={getLocalisation('section')}
                                    name="section_id"
                                    rules={[{ required: true, message: getLocalisation('please_section') }]}
                                >
                                    <AutoComplete
                                        type="button"
                                        loading={sectionLoading}
                                        placeholder={getLocalisation('select_section')}
                                        onSearch={handleSearchSection}
                                        onCreate={handleAddSectionClick}
                                        options={sections?.map((item: SectionItemProps) => ({
                                            ...item,
                                            value: item.id,
                                            label: item.name,
                                            description: <Space key={item.id} direction="vertical">
                                                <TypographyText disabled level="small">{getLocalisation('projects')}:</TypographyText>
                                                <Space>{item.projects?.map(({value, label}: any) => <Tag key={value} size="small">{label}</Tag>)}</Space>
                                            </Space>
                                        }))}
                                    />
                                </FormItem>
                                <FormItem
                                    rules={[{ required: true, message: getLocalisation('please_category') }]}
                                    label={getLocalisation('category')}
                                    name="category_id"
                                >
                                    <AutoComplete
                                        loading={categoryLoading}
                                        placeholder={getLocalisation('select_category')}
                                        options={categories}
                                        onCreate={sectionId ? handleSubmitCategory : undefined}
                                        onRemove={handleRemoveCategory}
                                        disabled={!sectionId && true}
                                    />
                                </FormItem>

                                <FormItem
                                    label={getLocalisation('tags')}
                                    name="tags"
                                >
                                    <TagsInput
                                        size="small"
                                        type='tags'
                                        placeholder={getLocalisation('search')}
                                        onCreate={handleSubmitTag}
                                        options={optionsTags}
                                    />
                                </FormItem>

                                <FormItem label={getLocalisation('add_documents')} name="files">
                                    <FilesList
                                        files={files}
                                        onCreate={handleCreateFile}
                                    />
                                </FormItem>
                            </Space>
                            <FormItem
                                label={getLocalisation('content')}
                                name="content"
                            >
                                <TextEditor/>
                            </FormItem>
                        </Space>
                    </Form>
                </Loader>
            </Modal>

            <KnowledgeVersionForm
                knowledgeId={action === 'edit' && id}
                id={formVersion}
                visible={!!formVersion}
                setVisible={setFormVersion}
                onSubmit={handleSubmitVersion}
            />

            <FilesModal
                id={fileId}
                visible={!!fileId}
                setVisible={setFileId}
                onSubmit={handleSubmitFile}
            />

            <KnowledgeSectionForm
                id="none"
                sectionName={sectionName}
                visible={visibleSectionForm}
                setVisible={setVisibleSectionForm}
                onSubmit={handleSubmitSection}
            />
        </>}</>
    );
};
