import React, {useContext, useRef, useState, useEffect} from "react";
import {
    Button, ButtonPopconfirm, FavoriteButton, FilesList,
    FlagsList, FlagsListProps, Space, Tag, TagsInput,
    Tooltip, TypographyText, TypographyTitle, TagsList,
    Affix, CopyButton, ButtonText, Popover, AffixProps, MemberView, Alert
} from "@components";
import {KnowledgeItemProps, KeywordsProps, ResponseItemProps} from "@types";
import { ForwardIcon, PointsIcon, PencilIcon } from "@assets/icons";
import { toDateTime, useBreakpoint, useTranslator } from "@helpers";
import {ControlsContext} from "@context";
import clsx from "clsx";

import "./ViewItem.less";
import {ResponseApi} from "@api";

const prefixCls = "cmp-view-item";

export interface ViewItemProps {
    simple?: boolean;
    record: KnowledgeItemProps;
    keywords?: KeywordsProps[];
    offsetTopCopy?: AffixProps['offsetTop']
    onCreateKeywords?: (label: any) => void;
    onChangeKeywords?: (values: any) => void;
    onEdit?: (record: KnowledgeItemProps) => void;
    onCopyContent?: () => void;
    onSend?: (record: KnowledgeItemProps) => void;
    onPublish?: (record: KnowledgeItemProps) => void;
    onReject?: (record: KnowledgeItemProps) => void;
    onArchive?: (id: KnowledgeItemProps['id']) => void;
    onRestoreArchive?: (id: KnowledgeItemProps['id']) => void;
    onVersionClick?: (id: any) => void;
    onChangeProjects?: () => void;
    onClickFavorites?: (id: any, metaData: any) => void;
    onClickIntegration?: () => void;
    onCreateLanguage?: FlagsListProps["onCreate"];
    onRemoveLanguage?: FlagsListProps["onRemove"];
    onChangeLanguage?: FlagsListProps["onChange"];
    onLinkEdit?: (record: KnowledgeItemProps) => void;
    targetAffix?: string
}

export const ViewItem: React.FC<ViewItemProps> = (props: ViewItemProps) => {
    const {
        onEdit, onSend, onPublish, onReject, record, onVersionClick, onCopyContent,
        onClickFavorites, keywords, onChangeKeywords,onChangeProjects, onCreateKeywords, onArchive,
        onRestoreArchive, onCreateLanguage, onRemoveLanguage, simple, onChangeLanguage,
        targetAffix = `#root`, onClickIntegration, onLinkEdit, offsetTopCopy = 8
    } = props;

    const { controls } = useContext(ControlsContext);
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [resultItems, setResultItems] = useState<any>([]);
    const { getLocalisation } = useTranslator("components");
    const { isTablet } = useBreakpoint();

    const contentRef = useRef<HTMLDivElement | null>(null)

    const handleClickVersions = (id: any) => {
        onVersionClick && onVersionClick(id)
        setMenuVisible(false);
    }
    
    const handleSendClick = ({ setVisible }: any) => {
        onSend && onSend(record)
        setVisible(false)
        setMenuVisible(false);
    }

    const handleArchive = (id: any, {setVisible}: any) => {
        onArchive && onArchive(id)
        setVisible(false);
        setMenuVisible(false);
    }

    const handleRestoreArchive = (id: any, { setVisible }: any) => {
        onRestoreArchive && onRestoreArchive(id)
        setVisible(false)
    }

    const handleEdit = (record: any) => {
        onEdit && onEdit(record);
        setMenuVisible(false);
    }

    const handleEditIntegration = () => {
        onClickIntegration && onClickIntegration();
        setMenuVisible(false);
    }

    const handleCreateVersion = () => {
        onEdit && onEdit(record)
        setMenuVisible(false);
    }

    const getTypeColor = (id: any) => {
        switch (id) {
            case 4: return "danger";
            case 3: return "success";
            case 2: return "warning";
            case 1: return "secondary";
        }
    }

    const isEditVersion = () => controls["component.knowledge.button.edit.view"] && onEdit && record.actions && record.actions.indexOf("edit") > -1

    const isEditIntegration = () => !!onClickIntegration

    const isCreateVersion = () => controls["component.knowledge.button.create.view"] && onEdit && record.actions && record.actions.indexOf("add_version") > -1

    const isSend = () => controls["component.knowledge.button.send.view"] && onSend && record.actions && record.actions.indexOf("ready") > -1

    const isPublish = () => controls["component.knowledge.button.publish.view"] && onPublish && record.actions && record.actions.indexOf("publish") > -1

    const isReject = () => controls["component.knowledge.button.reject.view"] && onReject && record.actions && record.actions.indexOf("reject") > -1

    const isArchive = () => controls["component.knowledge.button.archive.view"] && onArchive && record.articles_status_id === 1
    
    const isRestoreArchive = () => controls["component.knowledge.button.restore.view"] && onRestoreArchive && record.articles_status_id === 0

    const handleChangeKeywords = (values: any) => {
        onChangeKeywords && onChangeKeywords(values);
    }

    const handleCreateKeywords = (label: any) => {
        onCreateKeywords && onCreateKeywords(label);
    }

    const handleCopy = async (textareaRef: any, { setLoading, setSuccess }: any) => {
        setLoading(true);
        let textareaValue = "";

        if (contentRef.current) {
            const cloneNode: any = contentRef.current?.cloneNode(true);
            const systemNodes = Array.prototype.concat(...cloneNode.querySelectorAll("[data-element-type=system]"));

            systemNodes.forEach((element: any) => {
                element.remove();
            });

            textareaValue = cloneNode.textContent
        }

        textareaRef.current.value = textareaValue;

        setTimeout(() => {
            textareaRef.current.select();
            document.execCommand('copy');
            setLoading(false);
            setSuccess(true);
            onCopyContent?.();
        })
        setTimeout(() => {
            setSuccess(false)
        }, 2000)
    }

    const renderContent = (longText: any) => {
        return longText.replace(`contenteditable="true"`, "");
    }

    useEffect(() => {
        const fetchResultItems = async (id: any) => {
            const result: ResponseItemProps[] = await ResponseApi.getList(id);
            setResultItems(result);
        }

        if (record && record.id) {
            fetchResultItems(record.id).catch(console.error)

        }
    }, [record])

    return (
        <div className={clsx(`${prefixCls}`)}>
            <Space className={clsx(`${prefixCls}-body`)} direction="vertical" size="large">
                <FlagsList
                    value={record.language_id}
                    options={record.languages}
                    onChange={onChangeLanguage}
                    onCreate={onCreateLanguage}
                    onRemove={onRemoveLanguage}
                />
                {(onChangeProjects || (record.projects || []).length > 1) && <Space
                    className={clsx(`${prefixCls}-row-projects`)}
                    direction="horizontal"
                    size="middle"
                >
                    <TagsList
                        simple
                        value={(record.projects || []).map(({value}: any) => value)}
                        options={record.projects || []}
                    />
                    {onChangeProjects && <Button
                        size="small"
                        type="dropdown"
                        onClick={onChangeProjects}
                        beforeIcon={<PencilIcon/>}
                    />}
                </Space>}

                <Space direction="vertical" size="small">
                    <Space direction="horizontal" size="small" wrap>
                        {resultItems && resultItems.length > 0 && <Tag
                            type="error"
                            size="small"
                        >{getLocalisation('integration_with_platforms')}</Tag>}
                        {record.tags && record.tags.map(({value, type, label}: any) => (
                            <Tag
                                key={value}
                                type={type}
                                size="small"
                            >{label}</Tag>
                        ))}
                    </Space>
                    <div className={clsx(`${prefixCls}-top`)}>
                        <Space size='middle'>
                            <Space size='small'>
                                {isTablet && <CopyButton
                                    placement="left"
                                    className={`${prefixCls}-copy`}
                                    onClick={handleCopy}
                                />}
                                <TypographyTitle className={clsx(`${prefixCls}-title-break`)} level="title">{record.title}</TypographyTitle>
                            </Space>
                            <FavoriteButton
                                onClick={(_: any, metaData: any) => onClickFavorites && onClickFavorites(record.id, metaData)}
                                value={!!record.is_favorite}
                            />
                        </Space>
                        <Space size='small' wrap className={clsx(`${prefixCls}-actions`)}>
                            {onLinkEdit && <Tooltip
                                placement="topRight"
                                title={getLocalisation('go_edit_form')}
                            >
                                <Button
                                    size="small"
                                    type='dropdown'
                                    beforeIcon={<ForwardIcon />}
                                    className={clsx(`${prefixCls}-reject`)}
                                    onClick={() => onLinkEdit(record)}
                                />
                            </Tooltip>}

                            {isSend() && <ButtonPopconfirm
                                size="small"
                                type="secondary"
                                placement="topRight"
                                className={clsx(`${prefixCls}-send`)}
                                children={getLocalisation("on_publish", true)}
                                onClick={handleSendClick}
                                title={getLocalisation("want_on_publish")}
                            />}

                            {isPublish() && <ButtonPopconfirm
                                size="small"
                                type="secondary"
                                className={clsx(`${prefixCls}-publish`)}
                                title={getLocalisation("want_publish")}
                                onClick={() => onPublish && onPublish(record)}
                            >{getLocalisation("publish")}</ButtonPopconfirm>}

                            {isReject() && <Button
                                size="small"
                                type="secondary"
                                className={clsx(`${prefixCls}-reject`)}
                                onClick={() => onReject && onReject(record)}
                            >{getLocalisation("reject")}</Button>}

                            {(isCreateVersion() || isRestoreArchive() || isEditVersion() || isEditIntegration() || isArchive()) && <Popover
                                placement="bottomRight"
                                visible={menuVisible}
                                onVisibleChange={setMenuVisible}
                                content={<Space direction="vertical">
                                    {isCreateVersion() && <ButtonText
                                        size="small"
                                        type="primary"
                                        className={clsx(`${prefixCls}-pencil`)}
                                        onClick={handleCreateVersion}
                                    >{getLocalisation("add_version")}</ButtonText>}
                                    {isRestoreArchive() && <ButtonPopconfirm
                                        size="small"
                                        type="secondary"
                                        placement="bottomRight"
                                        className={clsx(`${prefixCls}-restore-archive`)}
                                        children={getLocalisation('restore')}
                                        onClick={(mataDate: any) => handleRestoreArchive(record.id, mataDate)}
                                        title={getLocalisation('want_restore')}
                                    />}
                                    {isEditVersion() && <ButtonText
                                        size="small"
                                        type="primary"
                                        className={clsx(`${prefixCls}-pencil`)}
                                        onClick={() => handleEdit(record)}
                                    >{getLocalisation("edit")}</ButtonText>}
                                    {isEditIntegration() && <ButtonText
                                        size="small"
                                        onClick={handleEditIntegration}
                                        type='primary'
                                    >{getLocalisation('set_up_integration')}</ButtonText>}

                                    {isArchive() && <ButtonPopconfirm
                                        buttonType="text"
                                        size="small"
                                        type="primary"
                                        placement="bottomRight"
                                        className={clsx(`${prefixCls}-archive`)}
                                        children={getLocalisation('archive')}
                                        onClick={(mataDate: any) => handleArchive(record.id, mataDate)}
                                        title={getLocalisation('want_archive')}
                                    />}

                                </Space>}
                            >
                                <Button
                                    size="small"
                                    type="dropdown"
                                    beforeIcon={<PointsIcon/>}
                                />
                            </Popover>}

                        </Space>

                    </div>
                    {record.version && <>
                        {!!onVersionClick && (<Space>

                            <TypographyText level="middle">{getLocalisation("version")}:</TypographyText>

                            <Space size="middle">

                                <span
                                    className={clsx(`${prefixCls}-link`)}
                                    onClick={() => handleClickVersions(record.id)}
                                >
                                    <TypographyText
                                        level="middle"
                                        type="link"
                                    >{record.version}</TypographyText>
                                </span>

                                {!simple && record.articles_status_id === 1 &&
                                    <span>
                                        <TypographyText
                                            level="middle"
                                            type={getTypeColor(record.status_id)}
                                        >{record.status_name}</TypographyText>
                                    </span>}
                                {!simple && record.articles_status_id === 0 && <TypographyText
                                    level="middle"
                                    type="danger"
                                >{getLocalisation('in_archive')}</TypographyText>}
                            </Space>

                        </Space>)}
                        {!onVersionClick && <TypographyText level="middle">{getLocalisation("version")}: {record.version} </TypographyText>}
                    </>}

                    {keywords && <Space className={clsx(`${prefixCls}-keywords`)} direction={isTablet ? 'vertical' : 'horizontal'}>
                        <TypographyText>{getLocalisation("keywords")}:</TypographyText>
                        <TagsInput
                            minChars={1}
                            size="small"
                            onChange={handleChangeKeywords}
                            onCreate={handleCreateKeywords}
                            value={record.keywords ? record.keywords.map((item: any) => item.value) : []}
                            options={keywords}
                            placeholder={getLocalisation("write_keywords")}
                        />
                    </Space>}
                    {record.comment && <Alert
                        type="warning"
                        description={record.comment}
                    />}
                    <Space
                        className={`${prefixCls}-space-content`}
                        align="start"
                    >
                        <div
                            ref={contentRef}
                            className={clsx(`${prefixCls}-content-body`)}
                            dangerouslySetInnerHTML={{ __html: renderContent(record.content) }}
                        />
                        {!isTablet  && <Affix
                            offsetTop={offsetTopCopy}
                            target={() => document.querySelector(targetAffix) as HTMLElement}
                        >
                            <CopyButton
                                placement="left"
                                className={`${prefixCls}-copy`}
                                onClick={handleCopy}
                            />
                        </Affix>}
                    </Space>

                    {(record.files && record.files.length > 0) && <FilesList
                        readonly={true}
                        files={record.files}
                        value={record.files.map(({id}: any) => id)}
                    />}

                </Space>

                <Space justify="space-between">
                    <MemberView 
                        name={record.member_name || record.author} 
                        description={toDateTime(record.modify_date)}
                    />
                </Space>
            </Space>
        </div>
    );
};